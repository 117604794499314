import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { BillToCompanyMappingProvider } from "presentation/store/BillToCompanyMapping/BillToCompanyMappingProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import BillToCompanyMappingMaintenance from "presentation/view/section/BillToCompanyMapping/BillToCompanyMappingMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const BillToCompanyMappingContMain = () => {
    return <ANAInfoWrapper permission={Permission.BILL_TO_COMPANY_MAPPING_MAINTENANCE}>
        <MessageBarWrapper>
            <BillToCompanyMappingProvider>
                <GridStyles/>
                <BillToCompanyMappingMaintenance/>
            </BillToCompanyMappingProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default BillToCompanyMappingContMain;