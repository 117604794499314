import { BillToCompanyMappingEntity, EMPTY_BILL_TO_COMPANY_MAPPING_ENTITY } from "domain/entity/BillToCompanyMapping/BillToCompanyMappingEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";


export interface BillToCompanyMappingDropdownOptions {
    chargeTypeDropdownOptions: DropdownProps[],
    subChargeTypeDropdownOptions: { [key: string]: DropdownProps[] },
    operatingTmlDropdownOptions: DropdownProps[],
    serviceCodeDropdownOptions: DropdownProps[],
    soaDropdownOptions: DropdownProps[],
    opsLineDropdownOptions: DropdownProps[],
    billToCompanyDropdownOptions: DropdownProps[],
    marshallingTypeDropdownOptions: DropdownProps[],
    specHandingIndDropdownOptions: DropdownProps[],
    vesselTypeDropdownOptions: DropdownProps[],
    consortiumCodeDropdownOptions: DropdownProps[],
    forwarderCodeDropdownOptions: DropdownProps[],
    chargeCodeDropdownOptions: DropdownProps[],
    marshalCodeDropdownOptions: DropdownProps[],
    modalityDropdownOptions: DropdownProps[],
}
export interface BillToCompanyMappingViewChangeState extends BaseViewChangeSate {
}

export interface BillToCompanyMappingModel {
    isLoading:boolean,
    isShowEditPanel:boolean,
    isAdd:boolean,
    isEdit:boolean,
    dynamicOptions: BillToCompanyMappingDropdownOptions,
    billToCompanyMappingList: BillToCompanyMappingEntity[],
    selectedRows: BillToCompanyMappingEntity[],
    updatedRows: BillToCompanyMappingEntity[],
    currentRow: BillToCompanyMappingEntity,
    currentEditRow: BillToCompanyMappingEntity,
    
}

export const EMPTY_BILL_TO_COMPANY_MAPPING_MODEL: BillToCompanyMappingModel = {
    isLoading: false,
    isShowEditPanel: false,
    isAdd: false,
    isEdit: false,
    dynamicOptions: {
        chargeTypeDropdownOptions: [],
        subChargeTypeDropdownOptions: {},
        operatingTmlDropdownOptions: [],
        serviceCodeDropdownOptions: [],
        soaDropdownOptions: [],
        opsLineDropdownOptions: [],
        billToCompanyDropdownOptions: [],
        marshallingTypeDropdownOptions: [],
        specHandingIndDropdownOptions: [],
        vesselTypeDropdownOptions: [],
        consortiumCodeDropdownOptions: [],
        forwarderCodeDropdownOptions: [],
        chargeCodeDropdownOptions: [],
        marshalCodeDropdownOptions: [],
        modalityDropdownOptions: [],
    },
    billToCompanyMappingList: [],
    selectedRows: [],
    updatedRows: [],
    currentRow: {...EMPTY_BILL_TO_COMPANY_MAPPING_ENTITY},
    currentEditRow: {...EMPTY_BILL_TO_COMPANY_MAPPING_ENTITY},
}