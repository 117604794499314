import { BillToCompanyMappingConstant, billToCompanyMappingRequiredFieldList } from "presentation/constant/BillToCompanyMapping/BillToCompanyMappingConstant";
import { ActiveIndDroOpts } from "presentation/constant/DropDownOptions/Common/ActiveIndDroOpts";
import { statusDropdownOptions } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { useBillToCompanyMappingVM } from "presentation/hook/BillToCompanyMapping/useBillToCompanyMappingVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useBillToCompanyMappingTracked } from "presentation/store/BillToCompanyMapping/BillToCompanyMappingProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { memo, useCallback, useMemo, useState } from "react";
import { FieldType, HPHBreadcrumb, HPHCheckbox, HPHGroupTitle, IconButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, SidebarCaption, Sidebarheader, SidebarTitle, StyledAction, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const BillToCompanyMappingEditPanel = () => {
    const BILL_TO_COMPANY_MAPPING_CONSTANT = BillToCompanyMappingConstant.Table;
    const [billToCompanyMappingState] = useBillToCompanyMappingTracked();
    const billToCompanyMappingVM = useBillToCompanyMappingVM();
    const { currentEditRow, isAdd, isEdit } = billToCompanyMappingState;
    const [isLoading, setIsLoading] = useState(false);
    const messageBarVM = useMessageBarVM();

    const memoSubChgTypeOptions = useMemo(() => {
        const subChgTypeOptions = currentEditRow.chargeType ? billToCompanyMappingState.dynamicOptions.subChargeTypeDropdownOptions[currentEditRow.chargeType] : [];
        return subChgTypeOptions;
    }, [currentEditRow.chargeType, billToCompanyMappingState.dynamicOptions.subChargeTypeDropdownOptions])

    const memoChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.chargeType || ''}
                fieldValue={currentEditRow?.chargeType}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.CHARGE_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeType'}
                maxLength={60}
                options={billToCompanyMappingState.dynamicOptions.chargeTypeDropdownOptions}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billToCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.CHARGE_TYPE, billToCompanyMappingState.dynamicOptions.chargeTypeDropdownOptions, billToCompanyMappingVM, currentEditRow?.chargeType, isAdd, isEdit])

    const memoSubChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.subChargeType || ''}
                fieldValue={currentEditRow?.subChargeType}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.SUB_CHARGE_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'subChargeType'}
                maxLength={60}
                options={memoSubChgTypeOptions}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billToCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.SUB_CHARGE_TYPE, billToCompanyMappingVM, currentEditRow?.subChargeType, isAdd, isEdit, memoSubChgTypeOptions])

    const memoSoa = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.soa || ''}
                fieldValue={currentEditRow?.soa}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.SOA}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'soa'}
                maxLength={60}
                options={billToCompanyMappingState.dynamicOptions.soaDropdownOptions}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billToCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.SOA, billToCompanyMappingState.dynamicOptions.soaDropdownOptions, billToCompanyMappingVM, currentEditRow?.soa, isAdd, isEdit])

    const memoServiceCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.serviceCode || ''}
                fieldValue={currentEditRow?.serviceCode}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.SERVICE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'serviceCode'}
                maxLength={60}
                options={billToCompanyMappingState.dynamicOptions.serviceCodeDropdownOptions}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billToCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.SERVICE, billToCompanyMappingState.dynamicOptions.serviceCodeDropdownOptions, billToCompanyMappingVM, currentEditRow?.serviceCode, isAdd, isEdit])

    const memoOpsLine = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.owner || ''}
                fieldValue={currentEditRow?.owner}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.OPS_LINE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'owner'}
                maxLength={60}
                options={billToCompanyMappingState.dynamicOptions.opsLineDropdownOptions}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billToCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.OPS_LINE, billToCompanyMappingState.dynamicOptions.opsLineDropdownOptions, billToCompanyMappingVM, currentEditRow?.owner, isAdd, isEdit])

    const memoForwarderReference = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.forwarderReference || ''}
                fieldValue={currentEditRow?.forwarderReference}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.FORWARDER_REF}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'forwarderReference'}
                maxLength={60}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billToCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.FORWARDER_REF, billToCompanyMappingVM, currentEditRow?.forwarderReference, isAdd, isEdit])

    const memoCntrStatus = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.cntrStatus || ''}
                fieldValue={currentEditRow?.cntrStatus}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.CONTAINER_STATUS}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cntrStatus'}
                maxLength={60}
                options={statusDropdownOptions}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billToCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.CONTAINER_STATUS, billToCompanyMappingVM, currentEditRow?.cntrStatus, isAdd, isEdit])

    const memoArrivalServiceCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.arrivalServiceCode || ''}
                fieldValue={currentEditRow?.arrivalServiceCode}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.SERVICE_ARRIVAL}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'arrivalServiceCode'}
                maxLength={60}
                options={billToCompanyMappingState.dynamicOptions.serviceCodeDropdownOptions}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billToCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.SERVICE_ARRIVAL, billToCompanyMappingState.dynamicOptions.serviceCodeDropdownOptions, billToCompanyMappingVM, currentEditRow?.arrivalServiceCode, isAdd, isEdit])

    const memoBillToCompanyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.billToCompanyCode || ''}
                fieldValue={currentEditRow?.billToCompanyCode}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.BILLTO_COMPANY}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'billToCompanyCode'}
                maxLength={60}
                sort={false}
                options={billToCompanyMappingState.dynamicOptions.billToCompanyDropdownOptions}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billToCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.BILLTO_COMPANY, billToCompanyMappingState.dynamicOptions.billToCompanyDropdownOptions, billToCompanyMappingVM, currentEditRow?.billToCompanyCode, isAdd, isEdit])

    const memoMarshallingType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.marshallingType || ''}
                fieldValue={currentEditRow?.marshallingType}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.MARSHALLING_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'marshallingType'}
                maxLength={60}
                options={billToCompanyMappingState.dynamicOptions.marshallingTypeDropdownOptions}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billToCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.MARSHALLING_TYPE, billToCompanyMappingState.dynamicOptions.marshallingTypeDropdownOptions, billToCompanyMappingVM, currentEditRow?.marshallingType, isAdd, isEdit])

    const memoSpecialHandlingInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.specialHandlingInd || ''}
                fieldValue={currentEditRow?.specialHandlingInd}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.SPECIAL_HANDLING_IND}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'specialHandlingInd'}
                maxLength={60}
                options={billToCompanyMappingState.dynamicOptions.specHandingIndDropdownOptions}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billToCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.SPECIAL_HANDLING_IND, billToCompanyMappingState.dynamicOptions.specHandingIndDropdownOptions, billToCompanyMappingVM, currentEditRow?.specialHandlingInd, isAdd, isEdit])

    const memoSpecialHandlingIndD1 = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.specialHandlingIndD1 || ''}
                fieldValue={currentEditRow?.specialHandlingIndD1}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.SPECIAL_HANDLING_IND_D1}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'specialHandlingIndD1'}
                maxLength={60}
                options={billToCompanyMappingState.dynamicOptions.specHandingIndDropdownOptions}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billToCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.SPECIAL_HANDLING_IND_D1, billToCompanyMappingState.dynamicOptions.specHandingIndDropdownOptions, billToCompanyMappingVM, currentEditRow?.specialHandlingIndD1, isAdd, isEdit])

    const memoVesselType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.vesselType || ''}
                fieldValue={currentEditRow?.vesselType}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.VESSEL_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'vesselType'}
                maxLength={60}
                options={billToCompanyMappingState.dynamicOptions.vesselTypeDropdownOptions}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billToCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.VESSEL_TYPE, billToCompanyMappingState.dynamicOptions.vesselTypeDropdownOptions, billToCompanyMappingVM, currentEditRow?.vesselType, isAdd, isEdit])

    const memoConsortiumCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.consortiumCode || ''}
                fieldValue={currentEditRow?.consortiumCode}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.CONSORTIUM}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'consortiumCode'}
                maxLength={60}
                options={billToCompanyMappingState.dynamicOptions.consortiumCodeDropdownOptions}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billToCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.CONSORTIUM, billToCompanyMappingState.dynamicOptions.consortiumCodeDropdownOptions, billToCompanyMappingVM, currentEditRow?.consortiumCode, isAdd, isEdit])

    const memoForwarderCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.forwarderCode || ''}
                fieldValue={currentEditRow?.forwarderCode}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.FORWARDER_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'forwarderCode'}
                maxLength={60}
                options={billToCompanyMappingState.dynamicOptions.forwarderCodeDropdownOptions}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billToCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.FORWARDER_CODE, billToCompanyMappingState.dynamicOptions.forwarderCodeDropdownOptions, billToCompanyMappingVM, currentEditRow?.forwarderCode, isAdd, isEdit])

    const memoOperatingTml = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.operatingTml || ''}
                fieldValue={currentEditRow?.operatingTml}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.OPERATING_TML}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'operatingTml'}
                maxLength={60}
                options={billToCompanyMappingState.dynamicOptions.operatingTmlDropdownOptions}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billToCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.OPERATING_TML, billToCompanyMappingState.dynamicOptions.operatingTmlDropdownOptions, billToCompanyMappingVM, currentEditRow?.operatingTml, isAdd, isEdit])

    const memoChargeCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.chargeCode || ''}
                fieldValue={currentEditRow?.chargeCode}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.CHARGE_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeCode'}
                maxLength={60}
                options={billToCompanyMappingState.dynamicOptions.chargeCodeDropdownOptions}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billToCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.CHARGE_CODE, billToCompanyMappingState.dynamicOptions.chargeCodeDropdownOptions, billToCompanyMappingVM, currentEditRow?.chargeCode, isAdd, isEdit])

    const memoDepartureServiceCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.departureServiceCode || ''}
                fieldValue={currentEditRow?.departureServiceCode}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.SERVICE_DEPARTURE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'departureServiceCode'}
                maxLength={60}
                options={billToCompanyMappingState.dynamicOptions.serviceCodeDropdownOptions}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billToCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.SERVICE_DEPARTURE, billToCompanyMappingState.dynamicOptions.serviceCodeDropdownOptions, billToCompanyMappingVM, currentEditRow?.departureServiceCode, isAdd, isEdit])

    const memoChangeOfVslInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.changeOfVslInd || ''}
                fieldValue={currentEditRow?.changeOfVslInd}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.CHANGE_OF_VESSEL_IND}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'changeOfVslInd'}
                maxLength={60}
                options={ActiveIndDroOpts().getDroOptsModel()}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billToCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.CHANGE_OF_VESSEL_IND, billToCompanyMappingVM, currentEditRow?.changeOfVslInd, isAdd, isEdit])

    const memoMarshalFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.marshalFrom || ''}
                fieldValue={currentEditRow?.marshalFrom}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.MARSHAL_FROM}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'marshalFrom'}
                maxLength={60}
                options={billToCompanyMappingState.dynamicOptions.marshalCodeDropdownOptions}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billToCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.MARSHAL_FROM, billToCompanyMappingState.dynamicOptions.marshalCodeDropdownOptions, billToCompanyMappingVM, currentEditRow?.marshalFrom, isAdd, isEdit])

    const memoMarshalTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.marshalTo || ''}
                fieldValue={currentEditRow?.marshalTo}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.MARSHAL_TO}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'marshalTo'}
                maxLength={60}
                options={billToCompanyMappingState.dynamicOptions.marshalCodeDropdownOptions}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billToCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.MARSHAL_TO, billToCompanyMappingState.dynamicOptions.marshalCodeDropdownOptions, billToCompanyMappingVM, currentEditRow?.marshalTo, isAdd, isEdit])

    const memoModalityOfArrival = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.modalityOfArrival || ''}
                fieldValue={currentEditRow?.modalityOfArrival}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.MODALITY_OF_ARRIVAL}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'modalityOfArrival'}
                maxLength={60}
                options={billToCompanyMappingState.dynamicOptions.modalityDropdownOptions}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billToCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.MODALITY_OF_ARRIVAL, billToCompanyMappingState.dynamicOptions.modalityDropdownOptions, billToCompanyMappingVM, currentEditRow?.modalityOfArrival, isAdd, isEdit])

    const memoModalityOfDeparture = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.modalityOfDeparture || ''}
                fieldValue={currentEditRow?.modalityOfDeparture}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.MODALITY_OF_DEPARTURE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'modalityOfDeparture'}
                maxLength={60}
                options={billToCompanyMappingState.dynamicOptions.modalityDropdownOptions}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billToCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.MODALITY_OF_DEPARTURE, billToCompanyMappingState.dynamicOptions.modalityDropdownOptions, billToCompanyMappingVM, currentEditRow?.modalityOfDeparture, isAdd, isEdit])

    const memoArrivalVesselCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.arrivalVesselCode || ''}
                fieldValue={currentEditRow?.arrivalVesselCode}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.VESSEL_CODEARRIVAL}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'arrivalVesselCode'}
                maxLength={60}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billToCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.VESSEL_CODEARRIVAL, billToCompanyMappingVM, currentEditRow?.arrivalVesselCode, isAdd, isEdit])

    const memoDepartureVesselCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.departureVesselCode || ''}
                fieldValue={currentEditRow?.departureVesselCode}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.VESSEL_CODEDEPARTURE}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'departureVesselCode'}
                maxLength={60}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billToCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.VESSEL_CODEDEPARTURE, billToCompanyMappingVM, currentEditRow?.departureVesselCode, isAdd, isEdit])

    const memoAlterableBillToCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.alterableBillToCompany || ''}
                fieldValue={currentEditRow?.alterableBillToCompany}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.ALTERABLE_BILLTO_COMPANY}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'alterableBillToCompany'}
                maxLength={60}
                sort={false}
                options={billToCompanyMappingState.dynamicOptions.billToCompanyDropdownOptions}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billToCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.ALTERABLE_BILLTO_COMPANY, billToCompanyMappingState.dynamicOptions.billToCompanyDropdownOptions, billToCompanyMappingVM, currentEditRow?.alterableBillToCompany, isAdd, isEdit])

    const memoArrivalSOA = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.arrivalSOA || ''}
                fieldValue={currentEditRow?.arrivalSOA}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.SOAARRIVAL}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'arrivalSOA'}
                maxLength={60}
                options={billToCompanyMappingState.dynamicOptions.soaDropdownOptions}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billToCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.SOAARRIVAL, billToCompanyMappingState.dynamicOptions.soaDropdownOptions, billToCompanyMappingVM, currentEditRow?.arrivalSOA, isAdd, isEdit])

    const memoDepartureSOA = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.departureSOA || ''}
                fieldValue={currentEditRow?.departureSOA}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.SOADEPARTURE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'departureSOA'}
                maxLength={60}
                options={billToCompanyMappingState.dynamicOptions.soaDropdownOptions}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billToCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.SOADEPARTURE, billToCompanyMappingState.dynamicOptions.soaDropdownOptions, billToCompanyMappingVM, currentEditRow?.departureSOA, isAdd, isEdit])

    const memoActiveInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "24px" }}>
            <NbisCommonField
                fieldKey={"activeInd"}
                requiredFieldList={billToCompanyMappingRequiredFieldList}
                fieldLabel={BILL_TO_COMPANY_MAPPING_CONSTANT.ACTIVE_IND}
                fieldType={FieldType.TEXT}
                isReadOnly={true}
                onFieldChange={() => { }} />
            <HPHCheckbox
                label={""}
                checked={currentEditRow?.activeInd === 'Y'}
                disabled={!(isAdd || isEdit)}
                onChange={(e) => billToCompanyMappingVM.onCheckboxChange(e.checked, "activeInd")}
            />
        </div>
        , [BILL_TO_COMPANY_MAPPING_CONSTANT.ACTIVE_IND, billToCompanyMappingVM, currentEditRow?.activeInd, isAdd, isEdit])

    const handleSave = useCallback(async () => {
        if (!currentEditRow.billToCompanyCode) {
            messageBarVM.showError("Bill To Company is mandatory.");
            return;
        }
        if (!currentEditRow.activeInd) {
            messageBarVM.showError("Active Ind is mandatory.");
            return;
        }
        setIsLoading(true);
        const data = await billToCompanyMappingVM.onSave(currentEditRow);
        if (data && data.success) {
            messageBarVM.showSuccess("Save Successfully.");
            billToCompanyMappingVM.searchAllBillToCompanyMappingList().then((data) => {
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
            });
        } else {
            setIsLoading(false)
            messageBarVM.showError(data.data);
            return;
        }
    }, [billToCompanyMappingVM, currentEditRow, messageBarVM]);

    const handClose = useCallback(() => {
        billToCompanyMappingVM.onCloseEidtPanel();
    }, [billToCompanyMappingVM]);

    const handleEdit = useCallback(() => {
        billToCompanyMappingVM.onEditClick();
    }, [billToCompanyMappingVM]);

    const handleReset = useCallback(() => {
        billToCompanyMappingVM.onResetClick(isAdd);
    }, [billToCompanyMappingVM, isAdd]);

    return <>
        <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
            <Sidebarheader style={{ width: '100%' }}>
                {isLoading && <Loader Indicator="Spinner" size="Medium" />}
                <Breadcrumb>
                    <HPHBreadcrumb breadcrumbData={[{ title: BillToCompanyMappingConstant.Title.CATEGORY },
                    { title: BillToCompanyMappingConstant.Title.TITLE }]} onCurrentClick={() => { }}>
                    </HPHBreadcrumb>
                </Breadcrumb>
                <StyledAction>
                    <>
                        {!(isAdd || isEdit) && <IconButton fileName='Icon-pen' size='medium' disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Edit'} onClick={handleEdit} />}
                        {(isAdd || isEdit) && <IconButton fileName="Icon-reset" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={() => handleReset()} />}
                        <IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement='bottom' toolTipText={'Close'} onClick={() => handClose()} />
                        {(isAdd || isEdit) && <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />}
                    </>
                </StyledAction>
            </Sidebarheader>



            <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <div className={'im-flex-row'}>
                            <div className={'flex-row-item-stretch'}>
                                <SidebarTitle>
                                    {isAdd && "New Bill To Company Setting"}
                                    {!isAdd && (currentEditRow.priority + "/" + currentEditRow.billToCompanyCode)}
                                </SidebarTitle>
                                {<SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                            </div>
                        </div>
                        <br />
                        <HPHGroupTitle titleText={BillToCompanyMappingConstant.Title.MAPPING_FROM} />
                        <CriteriaItemContainer>
                            {memoChargeType}
                            {memoSubChargeType}
                            {memoAlterableBillToCompany}
                        </CriteriaItemContainer>

                        <label>CONTAINER</label>
                        <CriteriaItemContainer>
                            {memoOpsLine}
                            {memoCntrStatus}
                            {memoForwarderCode}
                            {memoForwarderReference}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoChargeCode}
                            {memoMarshallingType}
                            {memoMarshalFrom}
                            {memoMarshalTo}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoSpecialHandlingInd}
                            {memoSpecialHandlingIndD1}
                            {memoModalityOfArrival}
                            {memoModalityOfDeparture}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoServiceCode}
                            {memoArrivalServiceCode}
                            {memoDepartureServiceCode}
                            {memoVesselType}
                        </CriteriaItemContainer>
                        <label>VOYAGE</label>
                        <CriteriaItemContainer>
                            {memoConsortiumCode}
                            {memoOperatingTml}
                            {memoArrivalVesselCode}
                            {memoDepartureVesselCode}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoSoa}
                            {memoArrivalSOA}
                            {memoDepartureSOA}
                            {memoChangeOfVslInd}
                        </CriteriaItemContainer>

                        <HPHGroupTitle titleText={BillToCompanyMappingConstant.Title.MAPPING_TO} />
                        <CriteriaItemContainer>
                            {memoBillToCompanyCode}
                            {memoActiveInd}
                        </CriteriaItemContainer>
                    </div>
                </div>
            </div>
        </StyledSidebar>
    </>
}

export default memo(BillToCompanyMappingEditPanel);
