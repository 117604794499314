import { ChargeOnBillToCompanyPolicy } from "constants/charge/ChargeOnBillToCompanyPolicy";
import { BillToCompanyMappingEntity, EMPTY_BILL_TO_COMPANY_MAPPING_ENTITY } from "domain/entity/BillToCompanyMapping/BillToCompanyMappingEntity";
import { MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { BillToCompanyMappingRepository } from "domain/repository/BillToCompanyMapping/BillToCompanyMappingRepo";
import { ConsortiumRepository } from "domain/repository/ChargeDetailMaintenance/ConsortiumRepo";
import { ChargeTypeRepository } from "domain/repository/ChargeType/ChargeTypeRepo";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import { OpsLineRepository } from "domain/repository/OpsLine/OpsLineRepo";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import { SpecialHandlingIndMappingRepository } from "domain/repository/SpecialHanldingInd/SpecialHandlingIndMappingRepo";
import { VesselTypeRepository } from "domain/repository/VesselType/VesselTypeRepo";
import _ from "lodash";
import { BillToCompanyMappingModel } from "presentation/model/BillToCompanyMapping/BillToCompanyMappingModel";
import { DropdownProps } from "presentation/model/DropdownProps";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface BillToCompanyMappingVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<BillToCompanyMappingModel>> | ((value: SetStateAction<BillToCompanyMappingModel>) => void),
    ],
    billToCompanyMappingRepo:BillToCompanyMappingRepository,
    chargeTypeRepo: ChargeTypeRepository,
    specialhandlingIndMappingRepo: SpecialHandlingIndMappingRepository,
    consortiumRepo: ConsortiumRepository,
    opsLineRepo: OpsLineRepository,
    masterDataRepo: MasterDataRepository,
    companyRepo: CompanyRepository,
    parameterDetailRepo: ParameterDetailRepository,
    vesselTypeRepo: VesselTypeRepository,
}

export const BillToCompanyMappingVM = ({dispatch, billToCompanyMappingRepo,chargeTypeRepo,specialhandlingIndMappingRepo,
    consortiumRepo,opsLineRepo,masterDataRepo,companyRepo,parameterDetailRepo,vesselTypeRepo
}:BillToCompanyMappingVMProps) => {
    const [billToCompanyMappingDispatch] = dispatch;

    const loadDropdownOption = async() => {

        await chargeTypeRepo.getAllChargeTypesForCombobox().then(
            chargeTypes => {
                let newChargeTypeList = _.orderBy(chargeTypes, ["chargeType", "subChargeType"]);
                let newChargeTypes = newChargeTypeList.filter(entity => entity.chargeCategory === 'GEN' || entity.chargeCategory === 'SYS');
                let chargeTypeDropdownOption:DropdownProps[] = [];
                let subChargeTypeDropdownOption:{[key:string]: DropdownProps[]} = {};
                newChargeTypes.forEach(chgTypeEty => {
                    const chgTypeExisted = chargeTypeDropdownOption.find(chgType =>
                        chgType.value === chgTypeEty.chargeType);
                    if (!chgTypeExisted) {
                        chargeTypeDropdownOption.push({
                            dropdownLabel: chgTypeEty.chargeType,
                            tagLabel: chgTypeEty.chargeType,
                            value: chgTypeEty.chargeType
                        })
                    }
                    if (chgTypeEty.subChargeType) {
                        if (!subChargeTypeDropdownOption[chgTypeEty.chargeType]) {
                            subChargeTypeDropdownOption[chgTypeEty.chargeType] = [];
                        }
                        subChargeTypeDropdownOption[chgTypeEty.chargeType].push({
                            dropdownLabel: chgTypeEty.subChargeType,
                            tagLabel: chgTypeEty.subChargeType,
                            value: chgTypeEty.subChargeType
                        });
                    }
                });

                billToCompanyMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeTypeDropdownOptions: chargeTypeDropdownOption,
                        subChargeTypeDropdownOptions: subChargeTypeDropdownOption
                    }
                }))
            }
        )

        await specialhandlingIndMappingRepo.getAllSpecialHandlingIndMappings().then(
            specialHandlingIndList => {
                const specialHandlingindDropdownOption = specialHandlingIndList?.map((specialHandlingInd) => ({
                    dropdownLabel: specialHandlingInd.specialHandlingInd,
                    tagLabel: specialHandlingInd.specialHandlingInd,
                    value: specialHandlingInd.specialHandlingInd,
                })) ?? []

                billToCompanyMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        specHandingIndDropdownOptions: specialHandlingindDropdownOption,
                    }
                }))
            }
        )
        await opsLineRepo.getAllOpsLines().then(
            opsLines => {
                const opsLinesDropdownOptions = opsLines?.map((opsLineEntity) => ({
                    dropdownLabel:opsLineEntity.opsLine,
                    tagLabel: opsLineEntity.opsLine,
                    value: opsLineEntity.opsLine,
                })) ?? []

                billToCompanyMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        opsLineDropdownOptions: opsLinesDropdownOptions
                    }
                }))
            }
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.TERMINAL).then(
            operatingTmls => {
                const operatingTmlDropdownOptions = operatingTmls?.map((operatingTml)=>({
                    dropdownLabel: operatingTml.code,
                    tagLabel: operatingTml.code,
                    value: operatingTml.code,
                })) ?? []

                billToCompanyMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        operatingTmlDropdownOptions: operatingTmlDropdownOptions,
                    }
                }))
            }           
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.MARSHAL).then(
            marshalEntities => {
                const marshalCodeDropdownOptions = marshalEntities?.map((marshal)=>({
                    dropdownLabel: marshal.code,
                    tagLabel: marshal.code,
                    value: marshal.code,
                })) ?? []

                billToCompanyMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        marshalCodeDropdownOptions: marshalCodeDropdownOptions,
                    }
                }))
            }           
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.HANDLING_CODE).then(
            chargeCodes => {
                const chargeCodeDropdownOptions = chargeCodes?.map((chargeCode)=>({
                    dropdownLabel: chargeCode.code,
                    tagLabel: chargeCode.code,
                    value: chargeCode.code,
                })) ?? []

                billToCompanyMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeCodeDropdownOptions: chargeCodeDropdownOptions,
                    }
                }))
            }           
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.MODALITY).then(
            modalityEntities => {
                const modalityCodeDropdownOptions = modalityEntities?.map((modality)=>({
                    dropdownLabel: modality.code,
                    tagLabel: modality.code,
                    value: modality.code,
                })) ?? []

                billToCompanyMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        modalityDropdownOptions: modalityCodeDropdownOptions,
                    }
                }))
            }           
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.FORWARDER_CODE).then(
            forwarderCodes => {
                const forwarderCodesDropdownOptions = forwarderCodes?.map((forwarderCode)=>({
                    dropdownLabel: forwarderCode.code,
                    tagLabel: forwarderCode.code,
                    value: forwarderCode.code,
                })) ?? []

                billToCompanyMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        forwarderCodeDropdownOptions: forwarderCodesDropdownOptions,
                    }
                }))
            }           
        )
        await consortiumRepo.getAllConsortiums().then(
            consortiums => {
                let consortiumCodeDropdownOptions = consortiums?.map((item) => ({
                    dropdownLabel: item.consortiumCode,
                    tagLabel: item.consortiumCode,
                    value: item.consortiumCode,
                })) ?? []
                consortiumCodeDropdownOptions = _.orderBy(consortiumCodeDropdownOptions, "dropdownLabel");

                billToCompanyMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        consortiumCodeDropdownOptions: consortiumCodeDropdownOptions,
                    }
                }))
            }
        );

        await masterDataRepo.getMasterDataByKey(MasterDataType.SERVICE).then(
            serviceCodes => {
                const serviceCodeDropdownOptions = serviceCodes?.map((serviceCode)=>({
                    dropdownLabel: serviceCode.code,
                    tagLabel: serviceCode.code,
                    value: serviceCode.code,
                })) ?? []

                billToCompanyMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        serviceCodeDropdownOptions: serviceCodeDropdownOptions
                    }
                }))
            }           
        );

        await masterDataRepo.getMasterDataByKey(MasterDataType.SOA).then(
            soas => {
                const soaCodeDropdownOptions = soas?.map((soa)=>({
                    dropdownLabel: soa.code,
                    tagLabel: soa.code,
                    value: soa.code,
                })) ?? []

                billToCompanyMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        soaDropdownOptions: soaCodeDropdownOptions
                    }
                }))
            }           
        );

        await masterDataRepo.getMasterDataByKey(MasterDataType.DUMMY_CHG_ON_BILL_TO).then(
            async dummyCompanies => {
                let dummyCompanyCodeDropdownOptions = dummyCompanies?.map((dummyCompany)=>({
                    dropdownLabel: dummyCompany.code,
                    tagLabel: dummyCompany.code,
                    value: dummyCompany.code,
                })) ?? []
                await companyRepo.getAllCompanyForCombobox().then(
                    companies => {
                        let companyCodeDropdownOptions = companies?.map((company) => ({
                            dropdownLabel: company.companyCode,
                            tagLabel: company.companyCode,
                            value: company.companyCode,
                        })) ?? []
                        companyCodeDropdownOptions = _.orderBy(companyCodeDropdownOptions, "dropdownLabel");
        
                        billToCompanyMappingDispatch(prevState => ({
                            ...prevState,
                            dynamicOptions: {
                                ...prevState.dynamicOptions,
                                billToCompanyDropdownOptions: [...dummyCompanyCodeDropdownOptions, ...companyCodeDropdownOptions],
                            }
                        }))
                    }
                )
            }           
        );

        await parameterDetailRepo.getAllParameterDtlsByParameterCode("MARSHALLING_TYPE").then(
            marshallingTypeList => {
                const marshallingTypeDropdownOption = marshallingTypeList?.map((marshallingType) => ({
                    dropdownLabel: marshallingType.parameterDtlCode,
                    tagLabel: marshallingType.parameterDtlCode,
                    value: marshallingType.parameterDtlCode,
                })) ?? []

                billToCompanyMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        marshallingTypeDropdownOptions: marshallingTypeDropdownOption
                    }
                }))
            }
        )
        await vesselTypeRepo.getVesselTypeForComboBox().then(
            vesselTypeList => {
                const vesselTypeDropdownOption = vesselTypeList?.map((vesselType) => ({
                    dropdownLabel: vesselType.vesselType,
                    tagLabel: vesselType.vesselType,
                    value: vesselType.vesselType,
                })) ?? []

                billToCompanyMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        vesselTypeDropdownOptions: vesselTypeDropdownOption
                    }
                }))
            }
        )

    }

    const searchAllBillToCompanyMappingList = async() => {
        billToCompanyMappingDispatch(prevState => {
            return {
                ...prevState,
                currentRow: {...EMPTY_BILL_TO_COMPANY_MAPPING_ENTITY},
                isShowEditPanel: false,
                isAdd: false,
                isEdit: false,
                selectedRows:[],
                billToCompanyMappingList: [],
            }
        })
        await billToCompanyMappingRepo.searchAllBillToCompanyMappingList().then(data =>{
            if(data){
                const newData = data.map(entity => ({  
                    ...entity,
                    billToCompanyCode: ChargeOnBillToCompanyPolicy().getChargeOnBillToCompanyKeyByValue(entity.billToCompanyCode as string),
                    alterableBillToCompany: ChargeOnBillToCompanyPolicy().getChargeOnBillToCompanyKeyByValue(entity.alterableBillToCompany as string),
                }));
                billToCompanyMappingDispatch(prevState => {
                    return {
                        ...prevState,
                        billToCompanyMappingList: newData,
                        selectedRows:[],
                    }
                })
            }else{
                billToCompanyMappingDispatch(prevState => {
                    return {
                        ...prevState,
                        billToCompanyMappingList: [],
                    }
                })
            }
        })
    }

    // const onRowDrag = (rows: BillToCompanyMappingEntity[]) => {
    //     billToCompanyMappingDispatch(prevState =>{ 
    //         const prevRows = prevState.billToCompanyMappingList;
    //         const toChangedRows = rows.map((e, index) => ({...e,tempPriority: prevRows[index].priority})).filter(e=>e.tempPriority!==e.priority);
    //         return ({
    //             ...prevState,
    //             billToCompanyMappingList: rows,
    //             updatedRows: toChangedRows,
    //     })})
    // }

    const onApply = async (updatedRows: BillToCompanyMappingEntity[]) => {

        return await billToCompanyMappingRepo.onApply(updatedRows);
    }

    const updateSelectedRows = async (rows:BillToCompanyMappingEntity[]) => {

        billToCompanyMappingDispatch(prevState => {
            return {
                ...prevState,
                selectedRows: rows,
            }
        })
    }

    const onHeaderFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;

        if(fieldKey === 'chargeType'){
            billToCompanyMappingDispatch(prevState => {
                return {
                    ...prevState,
                    currentEditRow: {
                        ...prevState.currentEditRow,
                        subChargeType: val===prevState.currentEditRow.chargeType?prevState.currentEditRow.subChargeType:''
                    },
                }
            })
        }
        billToCompanyMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {
                    ...prevState.currentEditRow,
                    [fieldKey]: val,
                },
            }
        })
    }

    const onRowDoubleClick = async (entity: BillToCompanyMappingEntity) => {
        billToCompanyMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: entity,
                currentRow: entity,
                isShowEditPanel: true,
            }
        })
    }

    const onCloseEidtPanel = async () => {
        billToCompanyMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {...EMPTY_BILL_TO_COMPANY_MAPPING_ENTITY},
                isShowEditPanel: false,
                isAdd: false,
                isEdit: false,
            }
        })
    }

    const onSave = async (currentRow: BillToCompanyMappingEntity) => {
        currentRow = {
            ...currentRow,
            billToCompanyCode:ChargeOnBillToCompanyPolicy().getChargeOnBillToCompanyValueByKey(currentRow.billToCompanyCode??""),
            alterableBillToCompany:ChargeOnBillToCompanyPolicy().getChargeOnBillToCompanyValueByKey(currentRow.alterableBillToCompany??""),
        }
        return billToCompanyMappingRepo.onSave(currentRow);
    }

    const onAddClick = async () => {
        billToCompanyMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {...EMPTY_BILL_TO_COMPANY_MAPPING_ENTITY},
                isShowEditPanel: true,
                isAdd: true,
            }
        })
    }
    const onResetClick = async (isAdd:boolean) => {
        billToCompanyMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: isAdd?{...EMPTY_BILL_TO_COMPANY_MAPPING_ENTITY}:prevState.currentRow,
            }
        })
    }
    const onEditClick = async () => {
        billToCompanyMappingDispatch(prevState => {
            return {
                ...prevState,
                isEdit: true,
            }
        })
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => { 
        billToCompanyMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {
                    ...prevState.currentEditRow,
                    [fieldName]: checked?"Y":"N",
                },
            }
        })
    } 

    return {
        onCheckboxChange: onCheckboxChange,
        onEditClick: onEditClick,
        onResetClick: onResetClick,
        onAddClick: onAddClick,
        onSave: onSave,
        onCloseEidtPanel: onCloseEidtPanel,
        onRowDoubleClick: onRowDoubleClick,
        onHeaderFieldChange: onHeaderFieldChange,
        updateSelectedRows: updateSelectedRows,
        onApply: onApply,
        // onRowDrag: onRowDrag,
        loadDropdownOption: loadDropdownOption,
        searchAllBillToCompanyMappingList: searchAllBillToCompanyMappingList,
    }
}