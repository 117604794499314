export interface BillToCompanyMappingEntity{
    id?: number | null,
    priority?: number | null,
    tempPriority?: number | null,
    chargeType?: string | null,
    operatingTml?: string | null,
    cntrStatus?: string | null,
    subChargeType?: string | null,
    billToCompanyCode: string | null,
    changeOfVslInd?: string | null,
    soa?: string | null,
    arrivalSOA?: string | null,
    departureSOA?: string | null,
    owner?: string | null,
    forwarderCode?: string | null,
    forwarderReference?: string | null,
    vesselType?: string | null,
    chargeCode?: string | null,
    consortiumCode?: string | null,
    serviceCode?: string | null,
    arrivalServiceCode?: string | null,
    departureServiceCode?: string | null,
    modalityOfArrival?: string | null,
    modalityOfDeparture?: string | null,
    arrivalVesselCode?: string | null,
    departureVesselCode?: string | null,
    alterableBillToCompany?: string | null,
    activeInd: string | null,
    marshallingType?: string | null,
    marshalFrom?: string | null,
    marshalTo?: string | null,
    specialHandlingInd?: string | null,
    specialHandlingIndD1?: string | null,
    mappingType: string,

    [key: string]: string | boolean | null | Object | undefined
}

export const EMPTY_BILL_TO_COMPANY_MAPPING_ENTITY : BillToCompanyMappingEntity = {

    id: 0,
    priority: null,
    tempPriority: null,
    chargeType: '',
    operatingTml: '',
    cntrStatus: '',
    subChargeType: '',
    billToCompanyCode: '',
    changeOfVslInd: '',
    soa: '',
    arrivalSOA: '',
    departureSOA: '',
    owner: '',
    forwarderCode: '',
    forwarderReference: '',
    vesselType: '',
    chargeCode: '',
    consortiumCode: '',
    serviceCode: '',
    arrivalServiceCode: '',
    departureServiceCode: '',
    modalityOfArrival: '',
    modalityOfDeparture: '',
    arrivalVesselCode: '',
    departureVesselCode: '',
    alterableBillToCompany: '',
    activeInd: 'Y',
    marshallingType: '',
    marshalFrom: '',
    marshalTo: '',
    specialHandlingInd: '',
    specialHandlingIndD1: '',
    mappingType: "BillToCompanyMappingEntity",
}