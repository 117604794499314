import { BillToCompanyMappingRepoImpl } from "domain/repository/BillToCompanyMapping/BillToCompanyMappingRepoImpl";
import { ConsortiumRepoImpl } from "domain/repository/ChargeDetailMaintenance/ConsortiumRepoImpl";
import { ChargeTypeRepoImpl } from "domain/repository/ChargeType/ChargeTypeRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { OpsLineRepoImpl } from "domain/repository/OpsLine/OpsLineRepoImpl";
import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { SpecialHandlingIndMappingRepoImpl } from "domain/repository/SpecialHanldingInd/SpecialHandlingIndMappingRepoImpl";
import { VesselTypeRepoImpl } from "domain/repository/VesselType/VesselTypeRepoImpl";
import { useBillToCompanyMappingTracked } from "presentation/store/BillToCompanyMapping/BillToCompanyMappingProvider";
import { BillToCompanyMappingVM } from "presentation/viewModel/BillToCompanyMapping/BillToCompanyMappingVM";
import { useMemo } from "react";

export const useBillToCompanyMappingVM = () => {
    const [, setBillToCompanyMappingState] = useBillToCompanyMappingTracked();
    const billToCompanyMappingVM = useMemo(() =>
        BillToCompanyMappingVM({
            dispatch: [setBillToCompanyMappingState],
            billToCompanyMappingRepo: BillToCompanyMappingRepoImpl(),
            chargeTypeRepo: ChargeTypeRepoImpl(),
            specialhandlingIndMappingRepo: SpecialHandlingIndMappingRepoImpl(),
            opsLineRepo: OpsLineRepoImpl(),
            consortiumRepo: ConsortiumRepoImpl(),
            masterDataRepo: MasterDataRepoImpl(),
            companyRepo: CompanyRepoImpl(),
            parameterDetailRepo: ParameterDetailRepoImpl(),
            vesselTypeRepo: VesselTypeRepoImpl(),
        }), [setBillToCompanyMappingState])

    return billToCompanyMappingVM
}

