import { BillToCompanyMappingEntity } from "domain/entity/BillToCompanyMapping/BillToCompanyMappingEntity";
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { axiosGetData, axiosPostData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { BillToCompanyMappingRepository } from "./BillToCompanyMappingRepo";

export const BillToCompanyMappingRepoImpl = (): BillToCompanyMappingRepository => {
    
    const searchUrl = "/v1/searchAllBillToCompanyMappingList"

    const searchAllBillToCompanyMappingList = async() : Promise<BillToCompanyMappingEntity[]> => {
        
        return axiosGetData(referenceAxiosInstance,`${searchUrl}`,[]).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }
    const onApply = async(rows: BillToCompanyMappingEntity[]): Promise<ResponseEntity> => {
        const applyUrl = "/v1/billToCompanyMappingApply"
        return await axiosPostData(referenceAxiosInstance, `${applyUrl}`, rows);
    }

    const onSave = async(row: BillToCompanyMappingEntity): Promise<ResponseEntity> => {
        const saveUrl = "/v1/billToCompanyMappingSave"
        return await axiosPostData(referenceAxiosInstance, `${saveUrl}`, row);
    }


    return {
        onSave: onSave,
        onApply: onApply,
        searchAllBillToCompanyMappingList : searchAllBillToCompanyMappingList,
    }
}
