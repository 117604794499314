import { EMPTY_BILL_TO_COMPANY_MAPPING_MODEL } from 'presentation/model/BillToCompanyMapping/BillToCompanyMappingModel';
import { useState } from 'react';
import { createContainer } from "react-tracked";

const {
    Provider: BillToCompanyMappingProvider,
    useTracked: useBillToCompanyMappingTracked
} = createContainer(() => useState(EMPTY_BILL_TO_COMPANY_MAPPING_MODEL), {concurrentMode: true});
export { BillToCompanyMappingProvider, useBillToCompanyMappingTracked };

